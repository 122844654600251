<template>
  <div class="personal_tab_pane_content_pane2">
    <div v-if="dataList && !dataList.length > 0" style="padding: 100px;text-align: center;">
      暂无活动
    </div>
    <!-- <div v-else class="personal_tab_pane_content_pane2_item" v-for="(item,index) in 14" :key="index">
      <el-image src="https://ccc.xnrun.com/Wisdom_life/upfile/1606383306.png" alt=""></el-image>
      <p>基础动作训练</p>
      <p>适合初学者训练</p>
      <span>会员</span>
      <span>初级</span>
    </div> -->
    <!-- dataList -->
    <div class="personal_tab_pane_content_pane2_item" v-for="(item,index) in dataList" :key="index">
      <el-image :src="item.imgurl2 || 'https://ccc.xnrun.com/Wisdom_life/upfile/1606383306.png'" :alt="item.name"></el-image>
    </div>
    <el-pagination
      v-if="$screenWidth>1200"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default{
  data(){
    return{
      dataList:[],
      currentPage:1,
      pageSize:10,
      total:0
    }
  },

  created() {
    this.getCourse()
  },

  methods:{
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getCourse()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getCourse()
    },
    async getCourse(){
      // let res = await this.$http.get('/official/lesson/myLessons')
      let params = {
        target:'MINE',
        userId:window.localStorage.userid,
        pageNum:this.$screenWidth>1200?this.currentPage:'',
        pageSize:this.$screenWidth>1200?this.pageSize:''
      }
      let res = await this.$http.get('/app/train/listTrain',{params:params})
      console.log(res)

      this.dataList = res.data.data
      this.total = res.data.total
    }
  }
}
</script>

<style scoped lang="scss">
.personal_tab_pane_content_pane2{
  text-align: left;
  .personal_tab_pane_content_pane2_item{
    display: inline-block;
    width: 40%;
    margin: 40px 0;
    margin-right: 5%;
    &:nth-of-type(3n){
      // margin: 0;
    }
    .el-image{
      width: 100%;
      border-radius: 10px;
    }
    p{
      margin: 16px 0;
      &:nth-of-type(1){
        font-size: 24px;
        font-weight: bolder;
      }
      &:nth-of-type(2){
        color: #7b7b7b;
      }
    }
    span{
      display: inline-block;
      width: 50px;
      height: 30px;
      border-radius: 15px;
      margin-right: 10px;
      box-sizing: border-box;
      color: #fff;
      padding: 5px;
      text-align: center;
      &:nth-of-type(1){
        background-color: #FEBB34;
      }
      &:last-child{
        background-color: #FF6666;
      }
    }
  }
}

@media screen and (max-width:1200px){
  .personal_tab_pane_content_pane2{
    text-align: left;
    .personal_tab_pane_content_pane2_item{
      display: inline-block;
      width: 100%;
      margin: 5vh 0;
      // margin-right: 5%;
      &:nth-of-type(3n){
        margin: 0;
      }
      .el-image{
        width: 100%;
        border-radius: 10px;
      }
      p{
        margin: 16px 0;
        &:nth-of-type(1){
          font-size: 24px;
          font-weight: bolder;
        }
        &:nth-of-type(2){
          color: #7b7b7b;
        }
      }
      span{
        display: inline-block;
        width: 50px;
        height: 30px;
        border-radius: 15px;
        margin-right: 10px;
        box-sizing: border-box;
        color: #fff;
        padding: 5px;
        text-align: center;
        &:nth-of-type(1){
          background-color: #FEBB34;
        }
        &:last-child{
          background-color: #FF6666;
        }
      }
    }
  }
}
</style>
